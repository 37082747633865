<template>
    <div>
        <v-menu
            ref="datePickerMenu"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="dateFormatted"
                    v-bind="$attrs"
                    :hint="withoutHint === true ? '' : 'DD/MM/YYYY format'"
                    persistent-hint
                    :prepend-icon="withoutIcon === true ? '' : 'event'"
                    :disabled="disabled"
                    :clearable="clearable"
                    @click:clear="date = null"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                v-bind:value="value"
                no-title
                @input="datePickerMenu = false"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import moment from "moment";

export default {
    inheritAttrs: false,
    props: ["value","disabled", "withoutIcon", "withoutHint", "clearable"],
    data: vm => ({
        date: vm.value,
        dateFormatted: vm.formatDate(vm.value),
        datePickerMenu: false
    }),

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date);
        }
    },

    watch: {
        value() {
          this.date = this.value;
          this.dateFormatted = this.formatDate(this.value);
        },

        date(val) {
            this.dateFormatted = this.formatDate(this.date);
            this.$emit("input", val);
        }
    },

    methods: {
        formatDate(date) {
            if (!date) return null;
            return moment(date).format("DD/MM/YYYY");
        },
        parseDate(date) {
            if (!date) return null;

            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
    }
};
</script>
